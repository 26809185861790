.user_info {
    border-radius: 8px;
    height: fit-content;
 
}
.payment-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.payment-page{
    flex-grow: 1;
    width: 100%;
    flex-shrink:1
}
.user_info{
    flex-grow:1;
    flex-shrink:1;
}
.top-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    width: 100%;
}
.top-bar p{
    margin:8px;
  }
.payment-top-card{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color:white;
    
}
.card-header {
    display: flex;
    justify-content: space-between;
    padding: .5rem;
    margin-bottom: 0;
    background-color:transparent;
    border-bottom: 0px solid rgba(0,0,0,.125);

}
.payment-top-card .card-header p{padding:0px 15px 0px 5px; margin:0px; font-family:'Mulish'; font-weight: 500; font-size:14px;}
.payment-top-card .card-header .period{padding:0px 15px 0px 5px; margin:0px; font-family:'Mulish'; font-weight: 300 !important; font-size:14px;}
.payment-top-card .card-footer .unsub-btn{padding:10px;border:1px solid #828282; background-color: transparent; width:100px; margin:10px;line-height:12px ; font-family:'Roboto'; font-weight: 500; font-size:10px; border-radius:20px;}
.payment-top-card .card-footer .upgrade-btn{padding:10px;border:1px solid #E6368B;color:white; background-color: #E6368B; width:100px;margin:10px;line-height:12px ; font-family:'Roboto'; font-weight: 500; font-size:10px; border-radius: 20px;}

.payment-top-card .card-footer {
     border-top: 0px solid rgba(0,0,0,.125); 
     display:flex;
     flex-direction: row;
     justify-content: space-between;
     padding:2rem;
}
.payment-bottom-card{
    background: transparent;
    text-align: center;
    border:0px;
}
.payment-bottom-card .card-header p{padding:20px; 
    width:80%;
    text-align: center;
    
    color:
    #747F80;margin:0px; font-family:'Mulish'; font-weight: 500; font-size:16px; line-height: 21px;}

.payment-bottom-card .text-card{
    
    background-color: white;
    text-align: center;
    font-weight: 700;
    align-items: center;
    border-radius: 10px;
    font-size: 12px;
    padding:20px !important;
    flex-grow: 0;
}
.payment-bottom-card .text-card:hover{
    
    transform: scale(1.05);
    color:#E6368B;
}
.payment-bottom-card .card-footer .continue-btn{
    padding:10px;border:1px solid #E6368B;color:white; background-color: #E6368B; margin:10px;line-height:22px ; font-family:'Mulish'; width:70%; font-weight: 700; font-size:18px; border-radius: 5px;
}

/*----------3 dots ----------*/
.payment-page .menu-nav {
    background-color:white;
    display: flex;
    justify-content: space-between;
  }
  
  .payment-page .three-dots:after {
    cursor: pointer;
    color: black;
    content: '\22EE';
    font-size: 20px;
    padding: 0 5px;
  }
  
  .payment-page .dropdown a {
    text-decoration: none;
    color:#5E5E5E;
    font-size: 12px;
   padding:0px;
  }
  .payment-page .dropdown a div{
    padding:5px 10px;
    border:.5px solid rgb(219, 215, 215);
}
.payment-page .like_card{
    flex-direction: row;
    height: 100px;
}
.payment-page .dropdown {
    position: absolute;
    right: 10px;
    background-color:white;
    border:1px solid rgb(224, 223, 223);
    outline: none;
    opacity: 0;
    /*z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s; max-height: 5s;*/
    text-align: center;
    padding:0px;
  }
  .payment-page .cancel{
    color:#E6368B;
    background-color:#f5f5f5;
  }
  .payment-page .cancel:hover{
    color:#E6368B;
  }
  
  .payment-page .dropdown-container:focus {
    outline: none;
  }
  
  .payment-page .dropdown-container:focus .dropdown {
    opacity: 1;
    z-index: 100;
    max-height: 100vh;
    transition: opacity 0.2s, z-index 0.2s; max-height: 0.2s;
  }
.pay_success{
  display: block;
  text-align: center;
}
.plan_btn {
  background-color: white;
  border: white;
  color: #000;
  box-shadow: 0px 0px 9px #524242;
  border-radius: 10px;
}
.plan_btn:hover{
  background-color: #f2378812 !important;
    border: 1px solid;
    color: #E6368B;
}
.plan_btn:active{
  color: #E6368B !important;
  font-size: 18px !important;
  background-color: white !important;
}
.unsub_btn{
  background-color: #ffffff;
  border: 1px solid gray;
  color: #000;
  border-radius: 20px;
  font-size: 14px;
}
.Toastify__toast-container {
  z-index: 1056; /* Higher than Bootstrap modal */
}

.unsub_btn:hover{
  background-color: #E6368B !important;
  border: 1px solid #E6368B;
  color: #ffffff;
  border-radius: 20px;
  font-size: 14px;
}
form.checkout-form {
  box-shadow: 0px 1px 2px #ff4145d4;
  border-radius: 10px;
}
.subscribed-plan{
  box-shadow: 0px 1px 4px #E6368B !important;
  border: 1px solid #E6368B;
}
.up-btn{
  background-color: #E6368B;
  border-color: #E6368B;
}

select.custom-currency-select.form-select {
  width: 25%;
}
@media (max-width: 768px) {
  select.custom-currency-select.form-select {
    width: 75%;
    display: block;
    margin: 0 auto;
  }
}