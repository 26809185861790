img.profile_middle {
  height: 90vh;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-top: 2rem;
}

img.profile_left {
  height: 90vh;
  margin-top: 2rem;

}

img.profile_right {
  height: 90vh;
  margin-top: 2rem;

}

.profile_data {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.card-title.h5 {
  font-size: 14px;
  font-weight: 400;
}

.text-card {

  background-color: white;
  text-align: center;
  font-weight: 700;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
  padding: 20px !important;
  flex-grow: 0;
}

.payment-bottom-card .text-card:hover {

  transform: scale(1.3);
  color: #E6368B;
}

.card-button {
  width: 100%;
  border: 0px;
  padding: 0px;
  background: transparent;
  text-align: left;
}

/*----------3 dots ----------*/
.match-request-page .menu-nav {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.match-request-page .three-dots:after {
  cursor: pointer;
  color: black;
  content: '\22EE';
  font-size: 20px;
  padding: 0 5px;
}

.match-request-page .dropdown a {
  text-decoration: none;
  color: #5E5E5E;
  font-size: 12px;
  padding: 0px;
}

.match-request-page .dropdown a div {
  padding: 5px 10px;
  border: .5px solid rgb(219, 215, 215);
}

.match-request-page .like_card {
  flex-direction: row;
  height: 100px;
}

.match-request-page .dropdown {
  position: absolute;
  right: 40px;
  top: 20px;
  background-color: white;
  border: 1px solid rgb(224, 223, 223);
  outline: none;
  opacity: 0;
  /*z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s; max-height: 5s;*/
  text-align: center;
  padding: 0px;
}

.match-request-page .cancel {
  color: #E6368B;
  background-color: #f5f5f5;
}

.match-request-page .cancel:hover {
  color: #E6368B;
}

.match-request-page .dropdown-container:focus {
  outline: none;
}

.match-request-page .dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s;
  max-height: 0.2s;
}

/*---------------keen slider--------------------------*/
.matched-profile .number-slide1 {
  background-image: url('../../assets/images/matched-profile1.png');
  background-size: cover;
  width: 333px;
  /* padding: 50px; */
  border-radius: 30px;
  min-width: 400px !important;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.matched-profile .image-block {
  display: flex;
  flex-direction: column;
  justify-content: end;
  color: white;
  position: absolute;
  bottom: 10px;
}

.user_info {
  flex-grow: 1;
  flex-shrink: 1;
}

.display-block {
  flex-grow: 1;
  flex-shrink: 1;
}

.display {
  display: none;
}

.display1 {
  display: block;
}

.card_footer {
  text-align: center;
}

.display2 {
  display: none !important;
}

.action-buttons {
  outline: none;
  background-color: transparent;
  border: 0px;
}

.top-bar {
  position: sticky;
  top: 0;
  z-index: 0;
}

.page_left {
  position: fixed;
}

.page_right {
  position: relative;
  margin-left: 17rem;
}

.keen-slider.matched-profile ul {
  display: contents;
}

.slider-bottom {
  position: absolute;
  bottom: -0.6rem;
}

.slider_icons {
  background-image: url('../../assets/images/mr_bg_1.png');
  background-size: cover;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2.6rem;
  border-radius: 0px 0px 30px 30px;
  /* border: '1px solid #E6368B'; */
}

.slider_icons.pl {
  padding: 0.5rem 2.7rem;
}

.slider-bottom h5 {
  margin-left: 2rem !important;
}

.profile_data div:nth-child(2) {
  font-weight: 500 !important;
}

i.fa.fa-map-marker {
  padding-right: 5px;
}

/* .keen-slider__slide .number-slide1{
  transform: translate3d(273.5px, 0px, 0px) !i;
  min-width: 532px;
  max-width: 532px;
} */
.data_btn {
  display: block;
  margin: auto;
  background-color: #E6368B;
}

.data_status p {
  width: 33%;
  display: block;
  margin: auto;
}

.keen-slider__slide.number-slide1 {
  margin-left: 4rem;
}

.slider_toph {
  background-image: url('../../assets/images/mr_bg_2.png');
  width: 100%;
  position: absolute;
  bottom: 4.3rem;
}
.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide{
  overflow: initial !important;
}

/* .mr_icons{
  justify-content: space-evenly;
  background-size: cover;
  padding: 10px 0px;
} */
.pl_lock {
  border-radius: 50px;
  position: absolute;
  right: 0px;
  bottom: 5rem;
  right: 1rem;
}

.pl_bg p {
  padding: 4rem 2rem;
  text-align: center;
  color: #747f80;
}

.pl_bg {
  background-image: url('../../assets/images/pl_bg.png');
  background-repeat: no-repeat;
  height: 150px;
  background-position: center;
}

.blur_btn {
  filter: blur(2px);
}

.loaderLottie {
  position: absolute;
  top: 35%;
  height: 150px;
}

.close_button {
  cursor: pointer;
}

.close-button {
  margin: 0px auto;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transform-origin: center;
  transform-style: preserve-3D;
}

.close-button:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}
.edit_pre{
  background: #ff4145;
  border-color: #ff4145;
}

::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
  }
  
  /* For vertical scrollbar track */
  ::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
  }
  
  /* For the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
  background: #888; /* color of the thumb */
  border-radius: 6px;
  }


  .carousel-indicators .active{
    background-color: #E6368B;
    
  }

  .carousel-indicators {
    margin-bottom: 0rem !important;
  }
  .share_close{
    color: #E6368B;
    font-size: 22px;
    border: none;
    background: none;
  }
.modal_blur{
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
}
i.fa.fa-copy{
  background: rgb(211, 211, 211);
  font-size: 16px;
  padding: 8px;
  color: mediumvioletred;
  border-radius: 20px;
  cursor: pointer;
}
.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide .mp{
  width: fit-content !important;
}
.navigation-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .keen-slider__slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background: #fff;
} */

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  fill: #E6368B;
  width: 24px;
  height: 24px;
}

.arrow--left {
  left: 10px;
}

.arrow--right {
  right: 10px;
}

.arrow--disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
}

.dot.active {
  background-color: #000;
}
.inner-logo{
  border: 1px solid #f23788;
  box-shadow: 0px 0px 2px #f23788;
}
@media (max-width: 768px) {
  .keen-slider__slide.number-slide1 {
      margin-left: 4rem;
  }
  .matched-profile .number-slide1{
      min-width: 270px !important;
  }
  .action-buttons img {
    width: 40px;
}
.info-res img {
  width: auto;
}
.slider_icons{
  padding: 0.5rem 0rem;
}
.slider_toph {
  bottom: 3.5rem;
}
.arrow{
  z-index: auto;
}
}

.profile_div {
  right: 0px;
  position: fixed;
  width: 400px;
}

@media only screen and (max-width: 600px) {
  .profile_div {
    top: 0px;
    right: 0px;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: -webkit-fill-available;
  }

  .profileView2{
    width: 100% !important;
    height: calc(100vh - 100px); overflow-y: auto; overflow-x: hidden;
  }
}
@media not all and (min-resolution: 0.001dpcm) and (min-color-index: 0) {
  .btn-safari {
      margin: 0px !important;
  }
}