.keen-slider__slide.number-slide1 {
  max-height: 600px !important;
}

/* .keen-slider__slide.number-slide2 {
    max-height: 600px !important;
}
.keen-slider__slide.number-slide3 {
    max-height: 600px !important;
} */
.match-request-page {
  flex-grow: 1;
  /* width: 100%; */
  flex-shrink: 1
}

.user_info {
  flex-grow: 1;
  flex-shrink: 1;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

}

.top-bar p {
  margin: 8px;
}

/*----------3 dots ----------*/
.match-request-page .menu-nav {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.match-request-page .three-dots:after {
  cursor: pointer;
  color: black;
  content: '\22EE';
  font-size: 20px;
  padding: 0 5px;
}

.match-request-page .dropdown1 a {
  text-decoration: none;
  color: #5E5E5E;
  font-size: 12px;
  padding: 0px;
}

.match-request-page .dropdown1 a div {
  padding: 5px 10px;
  border: .5px solid rgb(219, 215, 215);
}

.match-request-page .like_card {
  flex-direction: row;
  height: 100px;
}

.match-request-page .dropdown1 {
  position: absolute;
  right: 10px;
  background-color: white;
  border: 1px solid rgb(224, 223, 223);
  outline: none;
  opacity: 0;
  /*z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s; max-height: 5s;*/
  text-align: center;
  padding: 0px;
}

.match-request-page .cancel {
  color: #E6368B;
  background-color: #f5f5f5;
}

.match-request-page .cancel:hover {
  color: #E6368B;
}

.match-request-page .dropdown-container:focus {
  outline: none;
}

.match-request-page .dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s;
  max-height: 0.2s;
}

.type-select {
  border: 0px
}

.type-select option {
  background-color: transparent;
  outline: none;
  padding: 3px;
}

.slider-bottom-mr {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  background-image: url('../../assets/images/mr_bg_2.png');
  cursor: pointer;
}

.slider-bottom-mr h5 {
  margin-left: 2rem;

}

.mr_icons {
  justify-content: space-evenly;
  background-image: url('../../assets/images/mr_bg_1.png');
  background-size: cover;
  padding: 10px 0px;
}

.display {
  display: none;
}

.display1 {
  display: block;
}

.type-select {
  width: auto !important;
}

/* select.type-select.form-select{
    color: #E6368B !important;
  } */
.type-select.form-select:focus {
  border-color: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.range_slider [data-index=1] {
  background: dodgerblue;
}

.near_by {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 12px;
  padding: 3px;
  margin-right: 8px;
}

/* .keen-slider.matched-profile{
  resize: horizontal;
  overflow: auto;
} */
.nearby_head {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  font-size: 18px;
}

.near_cancel {
  background: none;
  border: none;
  color: black;
}

.near_ok {
  background: none;
  border: none;
  color: #E6368B;
}

.match1 {
  margin-right: -1rem !important;
  background-color: #5E5E5E;
}

.match2 {
  height: 55px;
  z-index: 1;
}

.match3 {
  margin-left: -1rem !important;
  background-color: #5E5E5E;
}

.near_by_recent {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 12px;
  padding: 3px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.close_button {
  cursor: pointer;
}

.close-button {
  margin: 0px auto;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transform-origin: center;
  transform-style: preserve-3D;
}

.close-button:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}

.profile_div {
  right: 0px;
  position: fixed;
  width: 400px;
  height: calc(100vh - 80px); /* Ensure spaces around the minus sign */
  overflow: auto;
}


@media only screen and (max-width: 600px) {
  .top-bar {
    z-index: 0;
  }

  .profile_div {
    top: 0px;
    right: 0px;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: -webkit-fill-available;
  }

  .notifications_card {
    width: 100% !important;
  }
}