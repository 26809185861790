.gender_prefer{
    padding: 0rem 1rem;
}
select.preference_option.form-select {
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
}
.mutual-page{
    flex-grow: 1;
    width: 100%;
    flex-shrink:1
}
.user_info{
    flex-grow:1;
    flex-shrink:1;
}
.top-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

}
.top-bar p{
    margin:8px;
  }

  /*----------3 dots ----------*/
.mutual-page .menu-nav {
    background-color:white;
    display: flex;
    justify-content: space-between;
  }
  
.mutual-page .three-dots:after {
    cursor: pointer;
    color: black;
    content: '\22EE';
    font-size: 20px;
    padding: 0 5px;
  }
  
.mutual-page .dropdown a {
    text-decoration: none;
    color:#5E5E5E;
    font-size: 12px;
   padding:0px;
  }
.mutual-page .dropdown a div{
    padding:5px 10px;
    border:.5px solid rgb(219, 215, 215);
}
.mutual-page .like_card{
    flex-direction: row;
    height: 100px;
}
.mutual-page .dropdown {
    position: absolute;
    right: 40px;
    top: 20px;
    background-color:white;
    border:1px solid rgb(224, 223, 223);
    outline: none;
    opacity: 0;
    /*z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s; max-height: 5s;*/
    text-align: center;
    padding:0px;
  }
  .mutual-page .cancel{
    color:#E6368B;
    background-color:#f5f5f5;
  }
  .mutual-page .cancel:hover{
    color:#E6368B;
  }
  
  .mutual-page .dropdown-container:focus {
    outline: none;
  }
  
  .mutual-page .dropdown-container:focus .dropdown {
    opacity: 1;
    z-index: 100;
    max-height: 100vh;
    transition: opacity 0.2s, z-index 0.2s; max-height: 0.2s;
  }
  .display{
    display: none;
  }
  .display1{
    display:block;
  }
  .display2{
  display: none !important;
  }
  .action-buttons{
    outline: none;
    background-color: transparent;
    border: 0px;
  }
.animate{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
}
.mutualmatch_icons{
  position: absolute;
  /* margin-bottom: 1rem; */
}
.mutual_bg_icons{
  background-image: url('../../assets/images/icons_bg.png');
    background-size: contain;
     padding: 10px 2.6rem;
}
.slider-bottom.mm{
  bottom: -1rem !important;
}
.data_status_mm{
  color: #E6368B;
  text-align: center;
  margin-top: 5rem ;
  font-size: 24px;
}

.close_button {
  cursor: pointer;
}

.close-button {
  margin: 0px auto;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transform-origin: center;
  transform-style: preserve-3D;
}

.close-button:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}

.profile_div {
  right: 0px;
  position: fixed;
  width: 400px;
  height: calc(100vh - 80px); /* Ensure spaces around the minus sign */
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .profile_div {
    top: 0px;
    right: 0px;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: -webkit-fill-available;
  }
  
  .mutualmatch_icons {
    position: initial;
}
}