.display_bg {
    background-image: url("../../assets/images/display_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
}

input#formBasicUser {
    border: none;
    border-bottom: 1px solid #c4c4c4;
    border-radius: 0;
    background-color: #f1f7f8;
}
.content_login{
    height: 100vh;
    overflow-y: scroll;
}
.iam_page {
    background-image: url('../../assets/images/looking_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
}

span.note_p {
    color: #747f80;
    font-size: 14px;
}

select.looking_dropdown.form-select {
    background-color: #f1f7f8;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    margin-bottom: 1rem;
}

select.looking_dropdown.form-select:focus {
    box-shadow: none;
}

.about_page {
    background-image: url("../../assets/images/about_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.moreabout_page {
    background-image: url("../../assets/images/moreabout_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.hobbies_page {
    background-image: url("../../assets/images/interests_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
}

textarea#exampleFormControlTextarea1 {
    background-color: #f1f7f8;
}

.custom-checkbox-input {
    display: none;
}

.custom-checkbox-text {
    /* padding: 1rem; */
    /* background-color: #aaa; */
    color: #555;
    cursor: pointer;
    user-select: none;
    padding: 3px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    border-radius: 50px;
    color: #747F80;
    border: 1px solid #747F80;
}

.custom-checkbox-input:checked~span {
    border: 1px solid #E6368B;
    color: #E6368B;
    border-radius: 50px;
    /* margin:0 5px; */
}

.custom-checkbox {
    margin: 5px 5px;
    padding:3px 0px;
}

/* select:invalid {
    color: gray;
  } */
.dropdown-menu {
    height: 250px;
    overflow: auto;
}

input.caste_field {
    background-color: #f1f7f8;
    border-color: #f1f7f8;
    border-radius: 0;
    border-bottom: 1px solid gray !important;
}

input.caste_field:focus {
    box-shadow: none;
    background-color: #f1f7f8;
    border-color: #f1f7f8;
    border-radius: 0;
    border-bottom: 1px solid;
}

.multi-range-slider {
    border: none !important;
    box-shadow: none !important;
}

.multi-range-slider .ruler {
    display: none !important;
}

.multi-range-slider .bar-inner {
    background-color: #E6368B !important;
    border: solid 1px #ff0303 !important;
    box-shadow: none !important;
}

.range-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}

#range {
    -webkit-appearance: none;
    width: 100%;
}

#range:focus {
    outline: none;
}

#range::before,
#range::after {
    position: absolute;
    top: 2rem;
    color: #333;
    font-size: 14px;
    line-height: 1;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, .1);
    border-radius: 4px;
}

#range::before {
    left: 0;
    content: attr(data-min);
}

#range::after {
    right: 0;
    content: attr(data-max);
}

#range::-webkit-slider-runnable-track {
    width: 100%;
    height: 1rem;
    cursor: pointer;
    animate: 0.2s;
    background: linear-gradient(90deg, #6BA132 var(--range-progress), #dee4ec var(--range-progress));
    border-radius: 1rem;
}

#range::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0.25rem solid #6BA132;
    box-shadow: 0 1px 3px rgba(0, 0, 255, .3);
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    height: 32px;
    width: 32px;
    transform: translateY(calc(-50% + 8px));
}

#tooltip {
    position: absolute;
    top: -2.25rem;
}

#tooltip span {
    position: absolute;
    text-align: center;
    display: block;
    line-height: 1;
    padding: 0.125rem 0.25rem;
    color: #fff;
    border-radius: 0.125rem;
    background: #6BA132;
    font-size: 1.25rem;
    left: 50%;
    transform: translate(-50%, 0);
}

#tooltip span:before {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: #6BA132;
}

p.terms_content {
    padding: 0px 5rem;
}

.disableCheckbox {
    pointer-events: none;
}

@media (max-width: 767px) {
    .terms_page {
        display: block !important;
    }

    p.terms_content {
        padding: 0px 2rem;
    }

    .privacy_page {
        display: block !important;
    }
}

.progress.active .progress-bar {
    -webkit-transition: none !important;
    transition: none !important;
}

span.error_msg_front {
    color: #ffffff;
    display: block;
    /* margin: auto; */
    text-align: center;
}

/* select.looking_dropdown.optional.form-select {
    color: grey;
} */
button.btn.dropdown-toggle.btn-light {
    border-bottom: 1px solid black !important;
    background-color: #f1f7f8;
    border-radius: 0px;
    border: none;
}

button.btn.dropdown-toggle.btn-light:focus {
    box-shadow: none;
}

.form-check-input:checked {
    background-color: #E6368B;
    border-color: #E6368B;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #2e2c29;
    text-decoration: none;
    background-color: white;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-toggle::before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f107";
    right: 0.9rem;
    position: absolute;
    font-size: 18px;
}

input[type="checkbox"] {
    accent-color: #E6368B;
}

button.actions-btn.btn.btn-light {
    display: none;
    color: #E6368B !important;
}

.dropdown .placeholder {
    color: #999;
    /* Replace with the desired color for the placeholder */
}

.looking_bg {
    background: url('../../assets/images/looking_bg.png');
    background-size: cover;
    background-position: center center;
    object-fit: contain;
}

@media only screen and (max-width: 2000px) and (min-width: 1380px)  {
    .looking_bg {
        height: 100vh;
    }
}

button.btn.dropdown-toggle.btn-light:hover {
    border: none;
    /* border-bottom: 1px solid ; */
}

/* button.btn.dropdown-toggle.btn-light:focus-visible{
   outline: none;
  }
  button.btn.dropdown-toggle.btn-light:active{
    border-style: none;
   } */