
  .settings-page{
    flex-grow: 1;
    width: 100%;
    flex-shrink:1
}
.outlet div#chat_data.chat1{
  padding: 10px;
}
.contact-page {
  flex-grow: 1;
  width: 100%;
  flex-shrink: 1;
}
.reports_list ul{
  flex-direction: row !important;
  cursor: pointer;
  padding: 0 !important;
}
.reports_list ul li{
  padding: 0 !important;
}
.password_val {
  display: inline-flex !important;
  flex-direction: row !important;
  margin-top: 0px !important;
}
ul.list-unstyled.password_val .ch_ps {
  font-size: 11px;
  padding: 2px;
}
.user_info{
    flex-grow:1;
    flex-shrink:1;
}
.top-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

}
.top-bar p{
  margin:8px;
}
  .settings-page ul{
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-top:30px;
  }
  
  .settings-page ul li{
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 20px;
      cursor: pointer;
  }
  .settings-page ul li:hover{
    background-color: #ffdddd;
  }
  .outlet{
      padding:40px 36px;
  }
  .outlet p{
      font-weight:400;
      font-size: 16px;
  }
  .outlet .contact input{display: block; border: 0px; border-bottom: 1px solid #cccccc; outline:none;padding: 5px; margin-bottom: 20px; font-size: 14px; width:80%}
  .outlet .contact textarea{display: block; border: 0px; border-bottom: 1px solid #cccccc;outline:none; padding: 5px; margin-bottom: 20px; font-size: 14px; width: 80%;}
  .outlet .contact .contact-btn{
      color:white;
      padding:5px;
      text-align: center;
      background-color: #E6368B;
      border:0px;
      border-radius:5px;
      font-size: 12px;
      margin-top: 40px;
  }

  /*----------------------------------------*/

  .Collapsible{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding:8px;
    margin:16px 0px;
    font-size:14px;
    border-radius: 5px;
  }
  .Collapsible__contentOuter{
      background-color: white;
  }
  .Collapsible p{
      color:#8e8e8e;
      padding-top: 5px;
      font-size: 14px;
  }
.settings-page .Tabs{
  flex-grow: 1;
}
  /*-------------------------notifications------------------*/

  .switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    right:4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #E6368B;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #E6368B;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .single-notification{
    display: flex;
    justify-content: space-between;
  }

  /*----------profile-lock-----------------*/
.profile-lock{text-align: center;}
.profile-lock p{color:#747f80;font-size:14px; line-height: 21px; font-weight: 500; margin:10px;}
.profile-lock h5{font-size:18px; line-height: 21px; font-weight: 700; margin:20px;}
.profile-lock button{color:white;background-color: #E6368B; font-size: 12px; padding:5px 15px; border-radius:5px; border:0px; margin:30px;}

.block-card{display: flex;align-items:center; border-radius: 5px; padding:5px 20px; justify-content: space-between;}
.block-card p{padding: 0px 1rem;}
/*-------------account-----------*/

.account p{color:#8e8e8e;font-size:13px;line-height: 15px; margin-top: .5rem; margin-bottom:5px ;}
.account h5{font-size:14px;line-height: 15px;}
.account button{font-size: 18px;background-color: #E6368B; color:white;padding: 5px 25px;border-radius:5px; border:0px; margin:30px;}


/*----------3 dots ----------*/
.settings-page .menu-nav {
  background-color:white;
  display: flex;
  justify-content: space-between;
}

.settings-page .three-dots:after {
  cursor: pointer;
  color: black;
  content: '\22EE';
  font-size: 20px;
  padding: 0 5px;
}

.settings-page .dropdown a {
  text-decoration: none;
  color:#5E5E5E;
  font-size: 12px;
 padding:0px;
}
.settings-page .dropdown a div{
  padding:5px 10px;
  border:.5px solid rgb(219, 215, 215);
}
.settings-page .like_card{
  flex-direction: row;
  height: 100px;
}
.settings-page .dropdown {
  position: absolute;
  right: 10px;
  background-color:white;
  border:1px solid rgb(224, 223, 223);
  outline: none;
  opacity: 0;
  /*z-index: -1;
  max-height: 0;
  transition: opacity 0.1s, z-index 0.1s; max-height: 5s;*/
  text-align: center;
  padding:0px;
}
.settings-page .cancel{
  color:#E6368B;
  background-color:#f5f5f5;
}
.settings-page .cancel:hover{
  color:#E6368B;
}

.settings-page .dropdown-container:focus {
  outline: none;
}

.settings-page .dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s; max-height: 0.2s;
}
.contact button{
  background-color: #E6368B !important;
  border-color: #E6368B !important;
  display: block;
  margin:auto;
  margin-top: 3rem;
}
.contact button:active{
 border: none !important;
}
.contact button:hover{
  border: none !important;
 }
button.settings_logout.btn.btn-primary {
  background: none;
  color: #0f0f0fe3;
  border: navajowhite;
  margin-left: 2rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  }

button.settings_logout.btn.btn-primary:hover {
  background: none !important;
}
button.settings_logout.btn.btn-primary:focus {
  background: none !important;
  box-shadow: none !important;
}
button.settings_logout.btn.btn-primary:active {
  background: none !important;
  }
  .settings-page ul li:active{
    background-color: #ffdddd;
    color: white;
  }
  button.reset_btn {
    background-color: #E6368B;
    border-color: #E6368B;
    display: block;
    margin: auto;
    margin-top: 1rem;
}
.block-card img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  object-fit: contain;
  background: #f2378812;
  padding: 2px;
}
.account a {
  text-decoration: none;
  color: black;
}
.account h5 {
  font-weight: 400;
  padding: 0.5rem 0rem;
}

.account_card p {
  box-shadow: 0px 2px 4px rgb(0 0 0 / 35%);
  font-size: 16px;
  padding: 16px 5px;
  border-radius: 5px;
  color: #2d2d2d;
  margin-top: 1.5rem;
}
.password_val {
  display: inline-flex !important;
  flex-direction: row;
}
li.active {
  background-color: #FFDDDD;
  color: black !important;
}
.settings_card p {
  box-shadow: 0px 2px 4px rgb(0 0 0 / 35%);
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #2d2d2d;
  margin-top: 1.5rem;
}
.error_msg_mob{
  margin-left: 5rem;
  font-size: 14px;
  color: #E6368B;
}
@media screen and (max-width: 600px) {
  .settings-page ul{
    display: grid;
    grid-template-columns: 1fr;
  }

  .notifications_card.notify {
    width: 100% !important;
  }
}

.close{
  background-color: transparent;
  border: none;
  outline: none;
  float: right;
}