.photos_bg {
  background-image: url("../../assets/images/addphotos.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

body {
  margin: 0px;
  padding: 0px;
  background: #f1f1f1;
}

.image-upload-one {
  grid-area: img-u-one;
  display: flex;
  justify-content: center;
}

.image-upload-two {
  grid-area: img-u-two;
  display: flex;
  justify-content: center;
}

.image-upload-three {
  grid-area: img-u-three;
  display: flex;
  justify-content: center;
}

.image-upload-four {
  grid-area: img-u-four;
  display: flex;
  justify-content: center;
}

.image-upload-five {
  grid-area: img-u-five;
  display: flex;
  justify-content: center;
}

.image-upload-six {
  grid-area: img-u-six;
  display: flex;
  justify-content: center;
}

.image-upload-container {
  display: inline-block;

}

.center {
  display: inline;
  margin: 1.5rem;
}

.form-input {
  /* width:100px;
    padding:3px; */
  /* background:#fff; */
  /* border:2px dashed grey; */
  border-radius: 10px;
}

.form-input input {
  display: none;
}

.form-input label {
  display: block;
  width: 100px;
  height: auto;
  max-height: 100px;
  /* background:#333; */
  /* background-image: url("../../assets/images/addphotos.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  border-radius: 10px;
  cursor: pointer;
}

/* .form-input label::before{
      content: "+";
  } */
.form-input img::before {
  content: "";
  z-index: initial;
}

.form-input img {
  width: 100px;
  height: 120px;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid gray;
  /* margin: 2px; */
  /* opacity: .4; */
}

.imgRemove {
  position: relative;
  bottom: 114px;
  padding: 0px 4px;
  left: 43%;
  background-color: transparent;
  border-radius: 30px;
  font-size: 0px;
  border: 1px solid #E6368B;
  outline: none;

}

.remove-button {
  display: none;
}

.imgRemove::after {
  content: 'x';
  color: rgb(255, 0, 0);
  font-weight: 900;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  display: block;

}

.small {
  color: firebrick;
  font-size: 15px;
}

.error_img {
  background-color: #E6368B;
}

.border-img {
  border-style: "dashed";
  border-color: "#E6368B";
  padding: 3px;
}

@media only screen and (max-width: 700px) {
  .image-upload-container {
    grid-template-areas: 'img-u-one img-u-two img-u-three'
      'img-u-four img-u-five img-u-six';
  }
}

.border {
  border: 2px dashed #E6368B !important;
}

.modal.add_photos {
  display: block;
  /* Initially hidden */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent background */
  z-index: 1000;
  /* Higher than other elements */
}

.modal-content.add_photos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.view {
  position: absolute;
  font-size: 18px;
  padding: 2px 5px;
  color: #E6368B;
  z-index: 1;
}

.view-1 {
  position: absolute;
  font-size: 18px;
  padding: 2px 5px;
  color: #E6368B;
  z-index: 1;
}

.view-2 {
  position: absolute;
  font-size: 18px;
  padding: 2px 5px;
  color: #E6368B;
  z-index: 1;
}

.view-3 {
  position: absolute;
  font-size: 18px;
  padding: 2px 5px;
  color: #E6368B;
  z-index: 1;
}

.view-4 {
  position: absolute;
  font-size: 18px;
  padding: 2px 5px;
  color: #E6368B;
  z-index: 1;
}

.view-5 {
  position: absolute;
  font-size: 18px;
  padding: 2px 5px;
  color: #E6368B;
  z-index: 1;
}

img.modal-image {
  width: 300px;
  height: 300px;
}

span.close {
  text-align: end;
  color: #E6368B;
  font-size: 24px;
  cursor: pointer;
}

.add_info {
  position: absolute;
  right: 0.4rem;
  bottom: 2rem;
  font-size: 12px;
}

.info-pic {
  position: absolute;
  right: 0.3rem;
  bottom: 1.5rem;
  color: #E6368B;
  padding: 5px;
  border-radius: 8px;
  font-size: 12px;
}

@media (min-width: 1920px) {
  .view {
    right: 41rem;
  }

  .view-1 {
    right: 31.8rem;
  }

  .view-2 {
    right: 22.5rem;
  }

  .view-3 {
    right: 41rem;
  }

  .view-4 {
    right: 31.8rem;
  }

  .view-5 {
    right: 22.5rem;
  }
}